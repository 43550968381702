<template>
  <v-row v-if="!sampleId">
    <v-col>
      Sample Not Found
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col cols="12" v-if="loading > 0">
      <LoadingBar />
    </v-col>
    <v-col cols="12">
      <image-uploader
        className="d-none"
        :debug="1"
        outputFormat="verbose"
        :preview="false"
        :capture="false"
        accept=".gif, .png, .jpg, .jpeg, .heic"
        doNotResize="gif"
        @input="setImage"
      >
        <label for="fileInput" slot="upload-label" ref="uploadLabel">
          <v-btn color="primary" @click="$refs.uploadLabel.click()">
            Select an Image to Upload
          </v-btn>
        </label>
      </image-uploader>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col
          v-for="file in files"
          :key="file.$id"
          class="d-flex child-flex"
          cols="6"
          sm="3"
        >
          <v-img
            :src="file.url"
            :lazy-src="lazySrc"
            aspect-ratio="1"
            class="sample-image grey lighten-2"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-container fluid class="image-overlay pa-2 fill-height">
              <v-row class="text-center">
                <v-col cols="12" v-if="file.error">
                  {{ file.error.msg }}
                </v-col>
                <v-col v-if="!readOnly">
                  <v-btn color="error" @click="deleteImage(file)">Delete</v-btn>
                </v-col>
                <v-col>
                  <v-btn color="primary" @click="setDialog(file)">
                    View
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-img>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog.active">
        <v-card>
          <v-img
            :src="dialog.file.url"
            max-height="80vh"
            max-width="100vw"
            contain
            class="grey darken-4"
          />

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="dialog.active = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import LoadingBar from "@/components/loaders/LoadingBar";
import { getBaseUrl } from "@/utils";
import { mapActions } from "vuex";
import hasIn from "lodash.hasin";
import ImageUploader from "vue-image-upload-resize";
import Vue from "vue";

export default {
  name: "SampleImages",
  props: {
    dzId: {},
    sampleId: {},
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  components: { LoadingBar, ImageUploader },
  data() {
    return {
      loading: 0,
      id: "sample-images",
      url: "sample-images",
      files: [],
      errors: [],
      dialog: {
        active: false,
        file: {}
      },
      // Transparent pixel
      lazySrc:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
    };
  },
  mounted() {
    this.setLoading(1);
    this.populateSampleImages();
  },
  methods: {
    setLoading(val) {
      if (val < 0 && this.loading <= 0) {
        this.loading = 0;
        return;
      }
      this.loading = this.loading + val;
    },
    ...mapActions(["notify"]),
    ...mapActions("samples", ["getPaginated", "deleteSampleImage"]),
    setDialog(file) {
      this.dialog.active = true;
      this.dialog.file = file;
    },
    deleteImage(file) {
      if (this.deleteSampleImage(file)) {
        this.files.splice(
          this.files.findIndex(item => item.id === file.id),
          1
        );
        const message = "Image Deleted Successfully";
        this.notify({ message });
      }
    },
    populateSampleImages() {
      this.getPaginated({ params: { id: this.sampleId } }).then(res => {
        if (!res || !hasIn(res, "data.data") || !res.data.data.length) {
          console.error(
            `Problem fetching sample data for id: - ${this.sampleId} `,
            res
          );
          return false;
        }
        const sample_images = !Array.isArray(res.data.data[0].sample_images)
          ? []
          : res.data.data[0].sample_images.map(item => ({
              id: item.id,
              $id: item.id,
              url: this.getUrl(item)
            }));

        this.files = [...this.files, ...sample_images];
        this.setLoading(-1);
      });
    },
    getUrl(obj) {
      return `${getBaseUrl()}/${this.url}/${obj.id}`;
    },
    setImage(obj) {
      if (obj && obj.dataUrl) {
        this.upload(this.dataURLtoBlob(obj.dataUrl));
      }
    },
    dataURLtoBlob(dataurl) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    upload(file) {
      this.setLoading(1);
      let data = new FormData();
      data.append("entity_id", this.sampleId);
      data.append("file", file);
      Vue.axios
        .post("/sample-images", data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(({ data }) => {
          this.files = [
            {
              ...data.data,
              url: this.getUrl(data.data)
            },
            ...this.files
          ];
          const message = "Image Added Successfully";
          this.notify({ message });
        })
        .catch(() => {
          const message = "Image Upload Failed";
          this.notify({ message });
        })
        .finally(() => {
          this.setLoading(-1);
        });
    }
  }
};
</script>

<style lang="scss">
.sample-image {
  .image-overlay {
    width: 100%;
    height: 100%;
    transition: opacity 100ms ease-in;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;

    &:hover {
      transition: opacity 300ms ease-in;
      opacity: 1;
    }
  }
}
</style>
